.footer{
    background-color: #2c3b30;
    display: flex;
    flex-direction: column;
    padding: 5em;
    color: #98bacb;
}
.footer--top{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer--top--one{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 4em;

}
.footer--top--input{
    background-color: transparent;
    outline: none;
    border: none;
    color: #98bacb;

}
.footer--top--input__container{
    border-bottom:2px solid rgb(180, 187, 188);
    border-radius: 1px;
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.footer--top--word__container--top{
    letter-spacing: 1.5px;
    padding: 0.5em 0;
    font-size: 1em;
    color: rgb(113, 113, 229);
}
.footer--top--word__container--bottom{
    letter-spacing: 2px;
    color: aliceblue;
    padding: 0.420em 0;
    /* bottom will be changed to be more responsive */
    font-size: 3em;
}
.footer--logo--name{
    color:#fc0e06;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 3px;
}

.footer__email--button{
    background-color: transparent;
    border: none;
    outline:none;
    cursor: pointer;
    padding:10px;
}
.footer__email--button:hover .footer__email--button--icon{
    color:#2d6717;
}

.footer__email--button--icon{
    color:#70ee3e;
    font-size: 1.5em;
    cursor: po;
}
.footer--social--link{
    padding: 15px;
    color:#70ee3e;
    position: relative;
    top: 10px;
    cursor: pointer;
}
.footer--social--link:hover{
    color:rgb(5, 106, 17)
}

.footer--top--two{
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    top: -25px;
}
.greenLine{
    color: #70ee3e;
}
.footer--bottom--list{
    padding: 5px;
}
.footer--top--image__container{
    width: fit-content;
    position: relative;
}
@keyframes myglow {
    from {
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  
    50% {
        box-shadow: 0px 0px 100px red, 0 0 100px #b678b078;
    }
    to{
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  }
  .glowEffect {
    width: 99px;
    height: 125px;
    transform: skewY(-29deg);
    position: absolute;
    top: 6%;
    right: 39%;
    border-radius: 10%;
    box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    transition: 1s ease all;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: myglow;
}

.footer--top--image{
    max-width: 450px;
}
.footer--bottom{
    display: flex;
    margin-left: 4em;

}

.footer--bottom--one{
    width: 50%;
    display: flex;
    justify-content: space-around;

}

.footer--bottom--one--item{
    text-align: center;
}
.footer--bottom--two{
    width: 50%;
    display: flex;
    justify-content: space-around;
}



@media only screen and (max-width: 1160px) {
    .footer--top--word__container--bottom {
        font-size: 2em;
        transition: 1s ease all;
    }
    .footer--top--image {
        max-width: 350px;
    }
    .glowEffect {
        width: 77px;
        height: 103px;
    }
}

@media only screen and (max-width: 860px) {
    .footer--top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 3em;

    }
    .footer--top--one{
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        padding-bottom: 2em;
        
    }
   .footer--top--word__container{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .footer--top--word__container--small{
       width: 80%;
   }
    .footer--top--input__container{
        margin: 0 auto;
    }
    .footer--top--image__container{
        position: relative;
        top: 1.5em;
    }
    .footer--top--image{
        max-width: 250px;
    }
    .glowEffect {
        width: 48px;
        height: 73px;
        right: 40%;
    }
    .footer--bottom{
        margin-left: 0;
        padding-bottom: 3em;
    }
    .footer--bottom--one{
        flex-direction: column;
        width: 100%;

        
    }
    .footer--logo--name{
        font-size: 1.5em;
    }
    .footer--bottom--two{
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .footer--bottom--two--item{
        margin-top: 1.5em;
    }
    
    
}

@media only screen and (max-width: 650px) {
    .footer{
        padding: 1em;
    }
    .footer--bottom{
        flex-direction: column;
    }
    .footer--logo--name{
        text-align: center;
    }
    .footer--bottom--two{
        text-align: center;
    }
    .footer--social--link{
        margin:0 1.5em;
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 480px) {
    .footer--top--one{
        left:0;
        width:100%;

    }
    .footer--top--word__container--bottom{
        font-size: 1.5em;
    }
    .footer--bottom--two--item{
        margin-top: 25px;
    }
    .footer--social--link{
        margin:0 0.69em;
    }
   
}

