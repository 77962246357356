.product__card__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px;
    padding-bottom: 6.9em;
    position: relative;
}
.product__card__container--info{
    position: absolute;
    left: 10%;
    top:-1%;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.9em;
    color: #bb0001;
}

.product__card{
    border: 5px solid #66bc34;
    border-radius: 25px;
    padding: 20px 20px 50px 20px;
    width: 20%;
    min-width: 200px;
    margin:15px 10px;
    background-color: #fff;
    position: relative;
    color:rgb(89, 86, 86);
}
.product__card--image{
    /* border: 1px solid red; */
}
.product__card--info--price{
    color: #bb0001;
    padding-top: 6.9420px;
}
.product__card--button__container{
    position: absolute;
    bottom:4%;
    width:100%;
    text-align: center;
    left: 0;
}

.product__card--button{
    background-color: #bb0001;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 30px;
    width: 101px;
    cursor: pointer;
}
.product__card--button:hover{
    background-color: rgb(240, 45, 45);
}
.empty--products{
    text-align: center;
    padding: 0 3em;
}
@media only screen and (max-width: 550px) {
    .product__card__container--info {
        top: -0.5%;
    }
}
@media only screen and (max-width: 420px) {
    .product__card {
        width: 43%;
        margin: 10px 10px;
        min-width: 100px;
    }
    
    
}