.header__container{
    display: flex;
    position: relative;
    /* value(s) below must change for mobile,etc */
    top:8em;
    margin-bottom: 10em;
}

.header__container--img{
    flex: 2;
    transition: 1s ease all;

}

.header__container--text--container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex:2;
    padding: 2em;
    
}
.header__container--text{
    color:#66bc34;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 3em; 
    transition: 1s ease all;
    /* value below must change for mobile,etc */
    font-size: 1.2em;
    font-family: 'Bowlby One SC', cursive;
}

.header__container--text--red{
    color:#bb0001;
}



@media only screen and (min-width: 1000px) {
    .header__container--text {
      font-size: 1.8em;
    }
  }

  @media only screen and (min-width: 1500px) {
    .header__container--text {
      font-size: 2.5em;
      width: 50%;
    }
    .header__container--img--mart{
        position: relative;
        right: -10%;
        transition: 1s ease all;

    }
  }
 
  @media only screen and (max-width: 650px) {
    .header__container{
      top:12em;
      margin-bottom: 15em;
    }
    .cart--open {
      width: 80%;
    }
    
  }

  @media only screen and (max-width: 550px) {
    .header__container{
      display: flex;
      flex-direction: column;
      margin-bottom: 10em;
    }
    .header__container--text--container {
      padding-bottom: 0;
  }
    .header__container--text {
      font-size: 1em;
      position: relative;
      top: -3em;
      font-size: 1.3em;
    }
    .header__container--img--mart{
      width: 50%;
      position: relative;
      left: 25%;
      top: -3em;
      transition: 1s ease all;

    }
  }

  @media only screen and (max-width: 420px) {
    .header__container--text{
      font-size: 0.8em;
      position: relative;
      left: -3em;
    }
    .header__container--img--mart{
      position: relative;
      left: 9em;
      transition: 1s ease all;

    }
  }

  @media only screen and (max-width: 300px) {
    .header__container--text{
      font-size: 0.5em;
      position: relative;
      left: -2em;
      top: -6.9em;
    }
    .header__container--img--mart{
      position: relative;
      left: 5em;
      transition: 1s ease all;

    }
 
  }