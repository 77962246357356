nav{
    display: flex;
    position: relative;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    position: fixed;
    width: 100%;  
    z-index: 100;  
    flex-direction: column;
    height: 15vh;
}

.nav--top{
    display: flex;
    align-items: center;
    height: 65%;
    width: 100%;
}


.logo__container{
    height: 100%;
    display: flex;
    align-items: center;
    width: 25%;
    
}

.logo__container--img{
    width:65%;
    min-width: 130px;
    max-width: 200px;
    cursor: pointer;
    transition: 0.5s ease all;
}
.input__container{
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

}
.input__container--input{
    border:none;
    border-radius: 5px;
    outline: none;
    width: 60%;
    height: 3em;
    transition: 0.5s ease all;
    padding-left: 10px;

}

.cart__container{
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
}
.cart__container--logo--button{
    position: relative;
    left:75%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s ease all;
}
.cart__container--logo--button:hover .cart__container--logo--i{
    color:#063c99;
 }
.cart__container--logo--i{
    color:#0165fe;
    font-size: 1.4em;
    padding: 10px;
}

.nav--bottom{
    height: 35%;
    width: 100%;
}

.nav--bottom__button{
    background-color: transparent;
    width:100%;
    height: 100%;
    color: #bb0001;
    transition: 0.3s ease all;
    cursor: pointer;
    border: none;
}
.nav--bottom__button:hover{
    background-color: rgb(58, 194, 58);
}
.nav--bottom--arrow--down{
    transition: 0.5s ease all;
    transform: rotate(0deg);
}
.nav--bottom--arrow--up{
    transition: 0.5s ease all;
    transform: rotate(180deg);
}

.category__modal--closed{
    height: 55vh;
    width: 100%;
    position: absolute;
    top: -40em;
    left: 0;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    z-index: -1;
    transition: 0.3s ease all;
    overflow: hidden;
}
.category__modal--open{
    height: 55vh;
    width: 100%;
    position: absolute;
    top: 0em;
    left: 0;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    z-index: -1;
    transition: 0.5s ease all;
    

}
.category__modal--item__container{
    position: relative;
    top: 8em;
    height: 69%;
    overflow: scroll;
}
.category__modal--item{
    height: 20%;
    width: 100%;
    background-color: transparent;
    border: none;
    transition: 0.3s ease all;
    cursor: pointer;
    color:rgb(30, 29, 29);
}
.category__modal--item:hover{
    background-color:rgb(58, 194, 58);
}



@media only screen and (max-width: 900px) {
    .input__container{
        width: 65%;
        text-align: center;
    }
    .input__container--input{
        width: 55%;
    }
    .cart__container--logo--button{
        position: relative;
        left:55%;
    }
    
}

@media only screen and (max-width: 650px) {
    nav{
        height: 20vh;
    } 
    .nav--top{
        height: 75%;
        display: flex;
        justify-content: space-between;
    }
    .nav--bottom{
        height: 25%;
    }
   
    .input__container{
        position: absolute;
        top: 50%;
        height: 1em;
        width: 100%;
        left:0;
    }
    .cart__container{
        top: -20px;
        position: relative;
    }
    .logo__container{
        top: -20px;
        position: relative;
    
    }
    .cart__container--logo--button{
        position: relative;
        left: 35%;
    }
    .category__modal--item__container{
        top: 9.3em;
    }
    .category__modal--open{
        height: 71vh;
    }    
}
  

  @media only screen and (max-width: 450px) {
    .cart__container--logo--button{
        position: relative;
        left: 25%;
    }
  }