html {
    box-sizing: border-box;
    font-size: 16px;
    font-family: 'Akshar', sans-serif;
    background-color: #f2eeee;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  
  ol, ul {
    list-style: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
nav{
    display: flex;
    position: relative;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    position: fixed;
    width: 100%;  
    z-index: 100;  
    flex-direction: column;
    height: 15vh;
}

.nav--top{
    display: flex;
    align-items: center;
    height: 65%;
    width: 100%;
}


.logo__container{
    height: 100%;
    display: flex;
    align-items: center;
    width: 25%;
    
}

.logo__container--img{
    width:65%;
    min-width: 130px;
    max-width: 200px;
    cursor: pointer;
    transition: 0.5s ease all;
}
.input__container{
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

}
.input__container--input{
    border:none;
    border-radius: 5px;
    outline: none;
    width: 60%;
    height: 3em;
    transition: 0.5s ease all;
    padding-left: 10px;

}

.cart__container{
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
}
.cart__container--logo--button{
    position: relative;
    left:75%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s ease all;
}
.cart__container--logo--button:hover .cart__container--logo--i{
    color:#063c99;
 }
.cart__container--logo--i{
    color:#0165fe;
    font-size: 1.4em;
    padding: 10px;
}

.nav--bottom{
    height: 35%;
    width: 100%;
}

.nav--bottom__button{
    background-color: transparent;
    width:100%;
    height: 100%;
    color: #bb0001;
    transition: 0.3s ease all;
    cursor: pointer;
    border: none;
}
.nav--bottom__button:hover{
    background-color: rgb(58, 194, 58);
}
.nav--bottom--arrow--down{
    transition: 0.5s ease all;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.nav--bottom--arrow--up{
    transition: 0.5s ease all;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.category__modal--closed{
    height: 55vh;
    width: 100%;
    position: absolute;
    top: -40em;
    left: 0;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    z-index: -1;
    transition: 0.3s ease all;
    overflow: hidden;
}
.category__modal--open{
    height: 55vh;
    width: 100%;
    position: absolute;
    top: 0em;
    left: 0;
    background: linear-gradient(to top, #66bc34f9,#f2eeee 70%);
    z-index: -1;
    transition: 0.5s ease all;
    

}
.category__modal--item__container{
    position: relative;
    top: 8em;
    height: 69%;
    overflow: scroll;
}
.category__modal--item{
    height: 20%;
    width: 100%;
    background-color: transparent;
    border: none;
    transition: 0.3s ease all;
    cursor: pointer;
    color:rgb(30, 29, 29);
}
.category__modal--item:hover{
    background-color:rgb(58, 194, 58);
}



@media only screen and (max-width: 900px) {
    .input__container{
        width: 65%;
        text-align: center;
    }
    .input__container--input{
        width: 55%;
    }
    .cart__container--logo--button{
        position: relative;
        left:55%;
    }
    
}

@media only screen and (max-width: 650px) {
    nav{
        height: 20vh;
    } 
    .nav--top{
        height: 75%;
        display: flex;
        justify-content: space-between;
    }
    .nav--bottom{
        height: 25%;
    }
   
    .input__container{
        position: absolute;
        top: 50%;
        height: 1em;
        width: 100%;
        left:0;
    }
    .cart__container{
        top: -20px;
        position: relative;
    }
    .logo__container{
        top: -20px;
        position: relative;
    
    }
    .cart__container--logo--button{
        position: relative;
        left: 35%;
    }
    .category__modal--item__container{
        top: 9.3em;
    }
    .category__modal--open{
        height: 71vh;
    }    
}
  

  @media only screen and (max-width: 450px) {
    .cart__container--logo--button{
        position: relative;
        left: 25%;
    }
  }
.header__container{
    display: flex;
    position: relative;
    /* value(s) below must change for mobile,etc */
    top:8em;
    margin-bottom: 10em;
}

.header__container--img{
    flex: 2 1;
    transition: 1s ease all;

}

.header__container--text--container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex:2 1;
    padding: 2em;
    
}
.header__container--text{
    color:#66bc34;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 3em; 
    transition: 1s ease all;
    /* value below must change for mobile,etc */
    font-size: 1.2em;
    font-family: 'Bowlby One SC', cursive;
}

.header__container--text--red{
    color:#bb0001;
}



@media only screen and (min-width: 1000px) {
    .header__container--text {
      font-size: 1.8em;
    }
  }

  @media only screen and (min-width: 1500px) {
    .header__container--text {
      font-size: 2.5em;
      width: 50%;
    }
    .header__container--img--mart{
        position: relative;
        right: -10%;
        transition: 1s ease all;

    }
  }
 
  @media only screen and (max-width: 650px) {
    .header__container{
      top:12em;
      margin-bottom: 15em;
    }
    .cart--open {
      width: 80%;
    }
    
  }

  @media only screen and (max-width: 550px) {
    .header__container{
      display: flex;
      flex-direction: column;
      margin-bottom: 10em;
    }
    .header__container--text--container {
      padding-bottom: 0;
  }
    .header__container--text {
      font-size: 1em;
      position: relative;
      top: -3em;
      font-size: 1.3em;
    }
    .header__container--img--mart{
      width: 50%;
      position: relative;
      left: 25%;
      top: -3em;
      transition: 1s ease all;

    }
  }

  @media only screen and (max-width: 420px) {
    .header__container--text{
      font-size: 0.8em;
      position: relative;
      left: -3em;
    }
    .header__container--img--mart{
      position: relative;
      left: 9em;
      transition: 1s ease all;

    }
  }

  @media only screen and (max-width: 300px) {
    .header__container--text{
      font-size: 0.5em;
      position: relative;
      left: -2em;
      top: -6.9em;
    }
    .header__container--img--mart{
      position: relative;
      left: 5em;
      transition: 1s ease all;

    }
 
  }
.product__card__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px;
    padding-bottom: 6.9em;
    position: relative;
}
.product__card__container--info{
    position: absolute;
    left: 10%;
    top:-1%;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.9em;
    color: #bb0001;
}

.product__card{
    border: 5px solid #66bc34;
    border-radius: 25px;
    padding: 20px 20px 50px 20px;
    width: 20%;
    min-width: 200px;
    margin:15px 10px;
    background-color: #fff;
    position: relative;
    color:rgb(89, 86, 86);
}
.product__card--image{
    /* border: 1px solid red; */
}
.product__card--info--price{
    color: #bb0001;
    padding-top: 6.9420px;
}
.product__card--button__container{
    position: absolute;
    bottom:4%;
    width:100%;
    text-align: center;
    left: 0;
}

.product__card--button{
    background-color: #bb0001;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 30px;
    width: 101px;
    cursor: pointer;
}
.product__card--button:hover{
    background-color: rgb(240, 45, 45);
}
.empty--products{
    text-align: center;
    padding: 0 3em;
}
@media only screen and (max-width: 550px) {
    .product__card__container--info {
        top: -0.5%;
    }
}
@media only screen and (max-width: 420px) {
    .product__card {
        width: 43%;
        margin: 10px 10px;
        min-width: 100px;
    }
    
    
}
.blood{
    color: #bb0001;
}
.cart--closed{
    height: 100vh;
    z-index: 666;
    position: fixed;
    right: -40%;
    width: 40%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
}
.cart--open{
    right: 0;
    height: 100vh;
    z-index: 666;
    position: fixed;
    width: 40%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
    overflow: scroll;
}
.cart--open--top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}
.cart--open--button--close__container{
    position: relative;
    left: 5%;
    
}
.cart--open--button--close{
    border: none;
    background-color: transparent;
    padding: 3px 9px 13px 9px;
    cursor: pointer;
}
.cart--open--button--close:hover .x--line{
    border-bottom: 4px solid #bb0001;
}
.x--line{
    border-bottom: 4px solid white;
    pad: 10px;
    width: 30px;
    height: 15px;
    transition: 0.2s ease all;
}
.x--line1{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative;
    top: 9px;
    left: 3.6px;
}
.x--line2{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: relative;
    top: -6.3px;
    right: 4.3px;
}

.cart--open--button--checkout__container{
    position: relative;
    right: 5%;
    padding: 4.20px;

}
.cart--open--button--checkout{
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.2s ease all;

}
.cart--open--button--checkout:hover .cart--open--button--checkout--i  {
   color:#bb0001;
}
.cart--open--button--checkout:hover .cart--open--button--checkout--p {
    color:#bb0001;
 }

.cart--open--button--checkout--i{
    color: white;
    font-size: 1.5em;
    transition: 0.2s ease all;

}
.cart--open--button--checkout--p{
    color: white;
    transition: 0.2s ease all;
    letter-spacing: 1px;
}
.cart--open--bottom{
    text-align: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;    
}
.cart--open--bottom__item{
    display: flex;
    background-color: #fff;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    max-width: 666px;
    margin: 10px;
}

.cart--open__item--image__container{
   height: 100%;
   width: 25%;
}
.cart--open__item--image{
    height: 100%;
    min-width: 55px;
 }

.cart--open__item--info__container{
    width: 75%;
} 
.cart__item--info--top {
    height: 50%;
    display: flex;
    justify-content: space-between;
}
.cart__item--info--bottom {
    height: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 6.9px;
}


.cart__item--info--top--left {
    width: 60%;
    display: flex;
    align-items: center;
} 
.cart__item--info--top--right {
    width: 20%;
    text-align: center;
}   
.cart__item--close__button{
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border: none;
    
}
.cart__item--close__button:hover .cart__item--close__button--icon{
    color: rgb(238, 49, 49);
}
.cart__item--close__button--icon {
    color: #bb0001;
    font-size: 1.5em;
}

.cart__item--quantity__container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    width: 50%;
}
.cart__item--quantity--button__container{
    border: 1px solid rgb(202, 199, 199);
    display: flex;
    align-items: center;
    border-radius: 3px;
}
.cart__item--quantity--button{
    background-color: rgb(245, 237, 237);
    border: none;
    outline: none;
    padding: 6px 12px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 3px;
    color: rgb(76, 74, 74);
}

.cart__item--quantity--total{
    padding: 0 15px;
}

.cart__item--total__container{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 6.9%;
}
.cart__item--total{
    color: #bb0001;
    font-size: 1.2em;
}


.cart__total__container{
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.cart__total{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    left: 55%;
}

.cart--empty--image{
    max-height: 420px;
}
.empty--text{
    color: rgb(255, 255, 255);
}



@media only screen and (max-width: 800px) {
    .cart--open{
        width: 60%;
    }
    .cart--open__item--image {
        max-height: 80px;
    }
}

@media only screen and (max-width:500px){
    .cart--open {
        width: 85%;
    }
}
.footer{
    background-color: #2c3b30;
    display: flex;
    flex-direction: column;
    padding: 5em;
    color: #98bacb;
}
.footer--top{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer--top--one{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 4em;

}
.footer--top--input{
    background-color: transparent;
    outline: none;
    border: none;
    color: #98bacb;

}
.footer--top--input__container{
    border-bottom:2px solid rgb(180, 187, 188);
    border-radius: 1px;
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.footer--top--word__container--top{
    letter-spacing: 1.5px;
    padding: 0.5em 0;
    font-size: 1em;
    color: rgb(113, 113, 229);
}
.footer--top--word__container--bottom{
    letter-spacing: 2px;
    color: aliceblue;
    padding: 0.420em 0;
    /* bottom will be changed to be more responsive */
    font-size: 3em;
}
.footer--logo--name{
    color:#fc0e06;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 3px;
}

.footer__email--button{
    background-color: transparent;
    border: none;
    outline:none;
    cursor: pointer;
    padding:10px;
}
.footer__email--button:hover .footer__email--button--icon{
    color:#2d6717;
}

.footer__email--button--icon{
    color:#70ee3e;
    font-size: 1.5em;
    cursor: po;
}
.footer--social--link{
    padding: 15px;
    color:#70ee3e;
    position: relative;
    top: 10px;
    cursor: pointer;
}
.footer--social--link:hover{
    color:rgb(5, 106, 17)
}

.footer--top--two{
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    top: -25px;
}
.greenLine{
    color: #70ee3e;
}
.footer--bottom--list{
    padding: 5px;
}
.footer--top--image__container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}
@-webkit-keyframes myglow {
    from {
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  
    50% {
        box-shadow: 0px 0px 100px red, 0 0 100px #b678b078;
    }
    to{
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  }
@keyframes myglow {
    from {
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  
    50% {
        box-shadow: 0px 0px 100px red, 0 0 100px #b678b078;
    }
    to{
        box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    }
  }
  .glowEffect {
    width: 99px;
    height: 125px;
    -webkit-transform: skewY(-29deg);
            transform: skewY(-29deg);
    position: absolute;
    top: 6%;
    right: 39%;
    border-radius: 10%;
    box-shadow: 0px 0px 40px red, 0 0 28px #b678b078;
    transition: 1s ease all;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: myglow;
            animation-name: myglow;
}

.footer--top--image{
    max-width: 450px;
}
.footer--bottom{
    display: flex;
    margin-left: 4em;

}

.footer--bottom--one{
    width: 50%;
    display: flex;
    justify-content: space-around;

}

.footer--bottom--one--item{
    text-align: center;
}
.footer--bottom--two{
    width: 50%;
    display: flex;
    justify-content: space-around;
}



@media only screen and (max-width: 1160px) {
    .footer--top--word__container--bottom {
        font-size: 2em;
        transition: 1s ease all;
    }
    .footer--top--image {
        max-width: 350px;
    }
    .glowEffect {
        width: 77px;
        height: 103px;
    }
}

@media only screen and (max-width: 860px) {
    .footer--top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 3em;

    }
    .footer--top--one{
        position: relative;
        width: 100%;
        left: 0;
        margin-left: 0;
        padding-bottom: 2em;
        
    }
   .footer--top--word__container{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .footer--top--word__container--small{
       width: 80%;
   }
    .footer--top--input__container{
        margin: 0 auto;
    }
    .footer--top--image__container{
        position: relative;
        top: 1.5em;
    }
    .footer--top--image{
        max-width: 250px;
    }
    .glowEffect {
        width: 48px;
        height: 73px;
        right: 40%;
    }
    .footer--bottom{
        margin-left: 0;
        padding-bottom: 3em;
    }
    .footer--bottom--one{
        flex-direction: column;
        width: 100%;

        
    }
    .footer--logo--name{
        font-size: 1.5em;
    }
    .footer--bottom--two{
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .footer--bottom--two--item{
        margin-top: 1.5em;
    }
    
    
}

@media only screen and (max-width: 650px) {
    .footer{
        padding: 1em;
    }
    .footer--bottom{
        flex-direction: column;
    }
    .footer--logo--name{
        text-align: center;
    }
    .footer--bottom--two{
        text-align: center;
    }
    .footer--social--link{
        margin:0 1.5em;
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 480px) {
    .footer--top--one{
        left:0;
        width:100%;

    }
    .footer--top--word__container--bottom{
        font-size: 1.5em;
    }
    .footer--bottom--two--item{
        margin-top: 25px;
    }
    .footer--social--link{
        margin:0 0.69em;
    }
   
}


.checkout__container--closed{
    height: 100vh;
    z-index: 667;
    position: fixed;
    right: -40%;
    width: 40%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
}
.checkout__container--open{
    right: 0;
    height: 100vh;
    z-index: 667;
    position: fixed;
    width: 100%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
    overflow: scroll;
}
.checkout--open--text__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    line-height: 3;
    color: aliceblue;
    font-size: 1.2em;
}
.checkout--open--button--close__container{
    position: relative;
    height: 10%;
}

.checkout--open--button--close{
    border: none;
    background-color: transparent;
    padding: 3px 9px 13px 9px;
    cursor: pointer;
    position: relative;
    left: 3%;
    top: 30%;
}

  
.checkout--open--button--close:hover .x--line{
    border-bottom: 4px solid #bb0001;
}
