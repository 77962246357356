.blood{
    color: #bb0001;
}
.cart--closed{
    height: 100vh;
    z-index: 666;
    position: fixed;
    right: -40%;
    width: 40%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
}
.cart--open{
    right: 0;
    height: 100vh;
    z-index: 666;
    position: fixed;
    width: 40%;
    background-color: #66bc34f5;
    padding: 1em;
    transition: 0.5s ease all;
    overflow: scroll;
}
.cart--open--top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}
.cart--open--button--close__container{
    position: relative;
    left: 5%;
    
}
.cart--open--button--close{
    border: none;
    background-color: transparent;
    padding: 3px 9px 13px 9px;
    cursor: pointer;
}
.cart--open--button--close:hover .x--line{
    border-bottom: 4px solid #bb0001;
}
.x--line{
    border-bottom: 4px solid white;
    pad: 10px;
    width: 30px;
    height: 15px;
    transition: 0.2s ease all;
}
.x--line1{
    transform: rotate(45deg);
    position: relative;
    top: 9px;
    left: 3.6px;
}
.x--line2{
    transform: rotate(-45deg);
    position: relative;
    top: -6.3px;
    right: 4.3px;
}

.cart--open--button--checkout__container{
    position: relative;
    right: 5%;
    padding: 4.20px;

}
.cart--open--button--checkout{
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.2s ease all;

}
.cart--open--button--checkout:hover .cart--open--button--checkout--i  {
   color:#bb0001;
}
.cart--open--button--checkout:hover .cart--open--button--checkout--p {
    color:#bb0001;
 }

.cart--open--button--checkout--i{
    color: white;
    font-size: 1.5em;
    transition: 0.2s ease all;

}
.cart--open--button--checkout--p{
    color: white;
    transition: 0.2s ease all;
    letter-spacing: 1px;
}
.cart--open--bottom{
    text-align: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;    
}
.cart--open--bottom__item{
    display: flex;
    background-color: #fff;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    max-width: 666px;
    margin: 10px;
}

.cart--open__item--image__container{
   height: 100%;
   width: 25%;
}
.cart--open__item--image{
    height: 100%;
    min-width: 55px;
 }

.cart--open__item--info__container{
    width: 75%;
} 
.cart__item--info--top {
    height: 50%;
    display: flex;
    justify-content: space-between;
}
.cart__item--info--bottom {
    height: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 6.9px;
}


.cart__item--info--top--left {
    width: 60%;
    display: flex;
    align-items: center;
} 
.cart__item--info--top--right {
    width: 20%;
    text-align: center;
}   
.cart__item--close__button{
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border: none;
    
}
.cart__item--close__button:hover .cart__item--close__button--icon{
    color: rgb(238, 49, 49);
}
.cart__item--close__button--icon {
    color: #bb0001;
    font-size: 1.5em;
}

.cart__item--quantity__container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    width: 50%;
}
.cart__item--quantity--button__container{
    border: 1px solid rgb(202, 199, 199);
    display: flex;
    align-items: center;
    border-radius: 3px;
}
.cart__item--quantity--button{
    background-color: rgb(245, 237, 237);
    border: none;
    outline: none;
    padding: 6px 12px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 3px;
    color: rgb(76, 74, 74);
}

.cart__item--quantity--total{
    padding: 0 15px;
}

.cart__item--total__container{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 6.9%;
}
.cart__item--total{
    color: #bb0001;
    font-size: 1.2em;
}


.cart__total__container{
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.cart__total{
    width: fit-content;
    position: relative;
    left: 55%;
}

.cart--empty--image{
    max-height: 420px;
}
.empty--text{
    color: rgb(255, 255, 255);
}



@media only screen and (max-width: 800px) {
    .cart--open{
        width: 60%;
    }
    .cart--open__item--image {
        max-height: 80px;
    }
}

@media only screen and (max-width:500px){
    .cart--open {
        width: 85%;
    }
}